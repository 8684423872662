import axios from 'axios' // 引入axios
import QS from 'qs' // 引入qs模块，用来序列化post类型的数据
import router from '../router'
import Vue from 'vue'
import Cookies from 'js-cookie'

axios.defaults.baseURL = '/hiq-admin'
axios.defaults.withCredentials = true
var CancelToken = axios.CancelToken
axios.interceptors.request.use((config) => {
  config['cancelToken'] = new CancelToken(function executor(cancel) {
    Vue.$httpRequestList.push(cancel) //存储cancle
  })
  config.headers.Authorization = window.localStorage.getItem('Token')
  // console.log(config)
  // 强行中断请求要用到的，记录请求信息
  return config
})
axios.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      return Promise.resolve(response)
    } else if (response.status === 401) {
      window.localStorage.setItem('Token', '')
      router.push('/login')
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.message === 'interrupt') {
      console.log('请求中断')
      return new Promise(() => {})
    }
    Vue.$httpRequestList = []
    return Promise.reject(error)
  }
)

export function _get(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
export function _loginPost(url, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: QS.stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function _post(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios
      .post(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
export function _put(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios
      .put(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
export function _delete(url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios
      .delete(url, {
        data: params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

// POST 方法封装  (文件上传)
export const uploadFileRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data'
    axios
      .post(url, JSON.stringify(params))
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
export const exportFileRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'post',
      data: JSON.stringify(params),
      responseType: 'blob',
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
