export const moduleChildren = [
  {
    path: 'healthIQ',
    name: 'healthIQ',
    component: () => import('@/views/module/healthIQ/Index.vue'),
    meta: {
      title: 'healthIQ',
    },
    children: [
      {
        path: 'healthIQList',
        name: 'healthIQList',
        component: () => import('@/views/module/healthIQ/healthIQList/Index.vue'),
        meta: {
          title: '列表管理',
        },
        children: [],
      },
    ],
  },
]
