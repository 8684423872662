import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import API from '@/request/api.js'
import * as echarts from 'echarts'
import '@/utils/vueFilter'
var _ = require('lodash')
Vue.prototype.$echarts = echarts
import '@/assets/sass/mixin.scss'
Vue.config.productionTip = false
Vue.config.silent = true
Vue.prototype.$API = API
Vue.prototype.$Axios = axios
Vue.prototype.$_ = _
//  cancelToken中的cancel函数
Vue.$httpRequestList = []
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
