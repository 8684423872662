import system from './components/system/index'
import { _get, _post, _delete, _loginPost, exportFileRequest } from './http'
// 活动
const huoDong = {
  getHuoDongList: (data) => _get('saas/project/list', data),
  add: (data) => _post('saas/project/add', data),
  update: (data) => _post('saas/project/update', data),
  delete: (data) => _post('saas/project/delete', data),
  updateStatus: (data) => _post('saas/project/updateStatus', data),
}

const healthIQ = {
  get: (data) => _post('bhiq/findHealthIQByCondition', data),
  add: (data) => _post('bhiq/add', data),
  update: (data) => _post('bhiq/update', data),
  delete: (data) => _post('bhiq/delete', data),
  ExportFile: (data) => exportFileRequest('bhiq/excel/healthExcelExportFile', data),
}
// 奖池
const jiangChi = {
  add: (data) => _post('saas/prizepool/add', data),
  update: (data) => _post('saas/prizepool/update', data),
  delPool: (data) => _post('saas/prizepool/delete', data),
  getPoolList: (data) => _get('saas/prizepool/list', data),
}
// 奖品
const jiangPin = {
  add: (data) => _post('saas/prize/add', data),
  update: (data) => _post('saas/prize/update', data),
  delete: (data) => _post('saas/prize/delete', data),
  getJiangPinList: (data) => _get('saas/prize/list', data),
  setJiangPinNumAndGaiLv: (data) => _post('saas/prize/updateRate', data),
}
// 规则
const guiZe = {
  add: (data) => _post('saas/active/add', data),
  update: (data) => _post('saas/active/update', data),
  getGuiZeList: (data) => _get('saas/active/list', data),
  delGuiZe: (data) => _post('saas/active/delete', data),
}
// 子活动
const ziHuoDong = {
  addZiHuoDong: (data) => _post('saas/model/add', data),
  getZiHuoDongList: (data) => _get('saas/model/list', data),
  delete: (data) => _post('saas/model/delete', data),
  update: (data) => _post('saas/model/update', data),
  updateStatus: (data) => _post('saas/model/updateStatus', data),
}
// 公司
const gongSi = {
  get: (data) => _get('saas/company/list', data),
  delGongSi: (data) => _post('saas/company/delete', data),
  addGongSi: (data) => _post('saas/company/add', data),
  editGongSi: (data) => _post('saas/company/update', data),
}
// 模式
const moShi = {
  getMoShiList: (data) => _post('saas/model/typeList', data),
  delete: (data) => _post('saas/model/typeDelete', data),
  add: (data) => _post('saas/model/typeAdd', data),
  update: (data) => _post('saas/model/typeUpdate', data),
}

// 角色
const role = {
  get: (data) => _get('admin/role/list', data), //角色表查询
  add: (data) => _post('admin/role/add', data),
  update: (data) => _post('admin/role/update', data),
  delete: (data) => _post('admin/role/remove', data),
  saveRole: (data) => _post('admin/role/saveRoleMenu', data),
}
// 用户
const user = {
  get: (data) => _get('admin/user/list', data), // 用户信息列表
  add: (data) => _post('admin/user/add', data),
  update: (data) => _post('admin/user/updateById', data),
  delete: (data) => _post('admin/user/remove', data),
  saveUserRole: (data) => _post('admin/user/saveUserRole', data),
  editPwd: (data) => _post('admin/user/editPwd', data),
}
const modelHiq = {
  hiqAllInit: (data) => _post('saas/modelhiq/hiqAllInit', data),
  hiqSelectedInit: (data) => _post('saas/modelhiq/hiqSelectedInit', data),
  sassOrderHealthIQBaseVChoice: (data) => _post('saas/modelhiq/sassOrderHealthIQBaseVChoice', data),
  updateYMD: (data) => _post('saas/modelhiq/updateYMD', data),
}
const dict = {
  get: (data) => _get('bhiq/dict/list', data),
  getDictTypeList: (data) => _get(`admin/dict/getDictByModelType`, data),
}
const card = {
  get: (data) => _get('saas/card/list', data),
  getById: (data) => _get('saas/card/getById', data),
  delete: (data) => _post('saas/card/delete', data),

  ExportFile: (data) => _post('saas/card/cardExcelExportFile', data),
  add: (data) => _post('saas/card/add', data),
  update: (data) => _post('saas/card/update', data),
}
export default {
  huoDong, // 活动
  jiangChi, // 奖池
  jiangPin, // 奖品
  gongSi, // 公司
  moShi, // 模式
  guiZe,
  ziHuoDong, // 子活动
  role, // 角色
  user, // 用户
  login: (data) => _loginPost('login', data),
  ...system,
  healthIQ,
  modelHiq,
  dict,
  card,
}
