import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Cookies from 'js-cookie'
import store from '../store'
import { systemChildren } from './system'

import { moduleChildren } from './module'
Vue.use(VueRouter)

const layoutChildren = [
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/home/Index.vue'),
    meta: {
      title: '首页',
    },
    children: [],
  },
  // 系统管理
  {
    path: '/system',
    name: 'system',
    component: () => import('@/views/system/Index.vue'),
    meta: {
      title: '系统管理',
    },
    children: systemChildren,
  },

  // j活动管理
  {
    path: '/huoDongManage',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '活动管理' },
    children: [
      {
        path: 'huoDongList',
        component: () => import('@/views/business/project/projectList/common/huoDongList.vue'),
        meta: { title: '活动列表' },
        children: [],
      },
      {
        path: 'ziHuoDongList',
        component: () => import('@/views/business/project/projectList/common/ziHuoDongList.vue'),
        meta: { title: '子活动列表' },
      },
    ],
  },
  // j选题管理
  {
    path: '/xuanTiManage',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '选题管理' },
    children: [
      {
        path: 'huoDongList2',
        component: () => import('@/views/business/project/projectList/common/xuanti.vue'),
        meta: { title: '活动列表' },
        children: [],
      },
      {
        path: 'ziHuoDongList2',
        component: () => import('@/views/business/project/projectList/common/ziHuoDongList2.vue'),
        meta: { title: '子活动列表' },
      },
      {
        path: 'xuanTi',
        component: () => import('@/views/business/project/projectList/common/modelHiq.vue'),
        meta: { title: '选题' },
      },
      {
        path: 'seeXuanTi',
        component: () => import('@/views/business/project/projectList/common/modelHiqList.vue'),
        meta: { title: '查看选题' },
      },
    ],
  },
  // j模式管理
  {
    path: '/moShiGuanLi',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '模式管理' },
    children: [
      {
        path: 'moShiList',
        component: () => import('@/views/business/project/projectList/common/moShiList.vue'),
        meta: { title: '模式列表' },
        children: [],
      },
    ],
  },
  // j规则管理
  {
    path: '/guiZeGuanLi',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '规则管理' },
    children: [
      {
        path: 'guiZeList',
        component: () => import('@/views/business/project/projectList/common/guiZeList.vue'),
        meta: { title: '规则列表' },
        children: [],
      },
    ],
  },
  // j奖池管理
  {
    path: '/poolGuanLi',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '奖池管理' },
    children: [
      {
        path: 'poolList',
        component: () => import('@/views/business/project/projectList/common/poolList.vue'),
        meta: { title: '奖池列表' },
      },
      {
        path: 'jiangPinList',
        component: () => import('@/views/business/project/projectList/common/jiangPinList.vue'),
        meta: { title: '奖品列表' },
      },
      {
        path: 'kaJunList',
        component: () => import('@/views/business/project/projectList/common/kaJunList.vue'),
        meta: { title: '奖品列表' },
      },
    ],
  },
  // j客户管理
  {
    path: '/keHuGuanLi',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '客户管理' },
    children: [
      {
        path: 'gongSiList',
        component: () => import('@/views/business/project/projectList/common/gongSiList.vue'),
        meta: { title: '公司列表' },
        children: [],
      },
    ],
  },
  // 业务管理
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/business/Index.vue'),
    meta: { title: '业务管理' },
    children: [
      {
        path: 'project',
        component: () => import('@/views/business/project/Index.vue'),
        meta: { title: '活动管理' },
        children: [
          {
            path: 'templateList',
            component: () => import('@/views/business/project/templateList.vue'),
            meta: { title: '活动模板列表' },
          },
          {
            path: 'modelTemplate',
            component: () => import('@/views/business/project/modelTemplate.vue'),
            meta: { title: '模式模板列表' },
          },
        ],
      },
    ],
  },
  // 模块管理
  {
    path: '/module',
    name: 'module',
    redirect: '/module/healthIQ',
    component: () => import('@/views/module/index.vue'),
    meta: { title: '模块管理' },
    children: moduleChildren,
  },
]
const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/layout/Index.vue'),
    meta: {
      title: '首页',
    },
    children: layoutChildren,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index.vue'),
  },
  { path: '*', redirect: '/404', hidden: true },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: 'page404',
    meta: { title: 'page404', noCache: true },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  clearHttpRequestingList()
  NProgress.start()
  console.log('to', to)
  let Token = window.localStorage.getItem('Token')
  if (to.name !== 'login' && !Token) return next('/login')
  window.sessionStorage.setItem('activePath', to.path)
  store.dispatch('setHomeTagsList', to)
  next()
})
// 清空cancelToken中的cancel函数
function clearHttpRequestingList() {
  // 路由切换检测是否强行中断， 强行中断时才向下执行
  if (Vue.$httpRequestList.length > 0) {
    Vue.$httpRequestList.forEach((item) => {
      // 给个标志，中断请求
      item('interrupt')
    })
    Vue.$httpRequestList = []
  }
}
router.afterEach(() => {
  NProgress.done()
})
export default router
