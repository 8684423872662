export const systemChildren = [
  {
    path: 'home',
    name: 'home',
    component: () => import('@/views/system/home/Index.vue'),
    meta: {
      title: '首页',
    },
    children: [],
  },
  {
    path: 'account',
    name: 'account',
    component: () => import('@/views/system/account/Index.vue'),
    meta: {
      title: '账号管理',
    },
    children: [
      {
        path: 'accountList',
        name: 'accountList',
        component: () => import('@/views/system/account/accountList/Index.vue'),
        meta: {
          title: '账号列表',
        },
        children: [],
      },
    ],
  },
  {
    path: 'menu',
    name: 'menu',
    component: () => import('@/views/system/menu/Index.vue'),
    meta: {
      title: '菜单管理',
    },
    children: [
      {
        path: 'menuList',
        name: 'menuList',
        component: () => import('@/views/system/menu/menuList/Index.vue'),
        meta: {
          title: '菜单列表',
        },
        children: [],
      },
    ],
  },
  {
    path: 'permission',
    name: 'permission',
    component: () => import('@/views/system/permission/Index.vue'),
    meta: {
      title: 'permission',
    },

    children: [
      {
        path: 'roles',
        name: 'roles',
        component: () => import('@/views/system/permission/roles/Index.vue'),
        meta: {
          title: 'roles',
        },
        children: [],
      },
    ],
  },

  {
    path: 'dict',
    name: 'dict',
    component: () => import('@/views/system/dict/Index.vue'),
    meta: {
      title: '字典管理',
    },

    children: [
      {
        path: 'tag',
        name: 'tag',
        component: () => import('@/views/system/dict/tag/Index.vue'),
        meta: {
          title: '标签管理',
        },
        children: [],
      },
    ],
  },
]
