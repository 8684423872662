export default {
  state: {
    menuList: [],
  },
  getters: {
    menuList(state) {
      return state.menuList
    },
  },
  mutations: {
    menuList(state, data) {
      // console.log(data);
      state.menuList = data
    },
  },
  actions: {
    menuList({ commit }, data) {
      commit('menuList', data)
    },
  },
}
