import Vue from 'vue'

const ziHuoDongStatus = (val) => {
  if (!val) {
    return '待上架'
  }
  switch (Number(val)) {
    case 1:
      return '下架'
    case 2:
      return '已上架'
    case 3:
      return '下架'
  }
}
const prizeCategory = (val) => {
  if (val) {
    if (val == 1) {
      return '实物奖品'
    } else if (val == 2) {
      return '卡券类'
    } else {
      return '感谢参与'
    }
  }
  return ''
}

Vue.filter('ziHuoDongStatus', ziHuoDongStatus)
Vue.filter('prizeCategory', prizeCategory)
