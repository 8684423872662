export default {
  state: {
    isCollapse: false,
    activePath: '/',
    breadcrumb: [],
    homeTagsList: [
      {
        path: '/index',
        name: 'index',
        label: '首页',
        icon: 'home',
        meta: {
          title: '首页',
        },
      },
    ],
    httpRequestList: [],
    innerHeight: window.innerHeight - 300,
  },
  getters: {
    innerHeight(state) {
      return state.innerHeight
    },
    homeTagsList(state) {
      return state.homeTagsList
    },
    isCollapse(state) {
      return state.isCollapse
    },
    activePath(state) {
      return state.activePath
    },
    breadcrumb(state) {
      return state.breadcrumb
    },
  },
  mutations: {
    updateCollapse(state, data) {
      state.isCollapse = data
    },
    setActivePath(state, data) {
      state.activePath = data
    },
    breadcrumb(state, data) {
      state.breadcrumb = data
    },
    delHomeTagsList(state, data) {
      state.homeTagsList = data
    },
    //选择标签 选择面包屑
    setHomeTagsList(state, val) {
      state.homeTagsList = state.homeTagsList.filter((item) => item.name !== 'login' && item.name !== 'page404')

      if (['/', '/index'].includes(val.path)) {
        state.activePath = '/index'
      } else {
        let result = state.homeTagsList.findIndex((item) => item.name === val.name)
        console.log(result)
        if (result === -1) {
          state.homeTagsList.push(val)
        }
      }
    },
    addHttpRequestList(state, payload) {
      if (payload == 0) {
        //强行中断时才向下执行
        state.httpRequestList.forEach((item) => {
          item('interrupt') //给个标志，中断请求
        })
        state.httpRequestList = []
      } else {
        state.httpRequestList.push(payload)
      }
    },
    setInnerHeight(state, data) {
      state.innerHeight = data
    },
  },
  actions: {
    setCollapse({ commit }, data) {
      commit('updateCollapse', data)
    },
    setActivePath({ commit }, data) {
      // console.log(data, 123)
      commit('setActivePath', data)
    },
    setbreadcrumb({ commit }, data) {
      commit('breadcrumb', data)
    },
    setHomeTagsList({ commit }, data) {
      commit('setHomeTagsList', data)
    },

    delHomeTagsList({ commit }, data) {
      commit('delHomeTagsList', data)
    },
    setInnerHeight({ commit }, data) {
      commit('setInnerHeight', data)
    },
    async removeHttpRequestList(ctx) {
      ctx.commit('addHttpRequestList', 0)
    },
  },
}
